import Head from "next/head";
import Link from "next/link";
import Script from "next/script";
import { ReactElement } from "react";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export function DefaultLayout(component: ReactElement) {
  return (
    <div className="flex h-full flex-col">
      <Script
        defer
        data-domain="fairyraffles.com"
        src="https://plausible.io/js/plausible.js"
      />
      <Head>
        <link rel="icon" href="/favicon/favicon.ico" />
        <title>Fairy Raffles, on-chain raffles made fair ✨</title>
        <meta
          name="title"
          content="Fairy Raffles, on-chain raffles made fair ✨"
        />
        <meta
          name="description"
          content="web3 raffling engine with verifiable randomness, each raffle is a smart contract"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fairyraffles.com/" />
        <meta
          property="og:title"
          content="Fairy Raffles, on-chain raffles made fair ✨"
        />
        <meta
          property="og:description"
          content="web3 raffling engine with verifiable randomness, each raffle is a smart contract"
        />
        <meta
          property="og:image"
          content="https://fairyraffles.com/assets/preview.png"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://fairyraffles.com/" />
        <meta
          property="twitter:title"
          content="Fairy Raffles, on-chain raffles made fair ✨"
        />
        <meta
          property="twitter:description"
          content="web3 raffling engine with verifiable randomness, each raffle is a smart contract"
        />
        <meta
          property="twitter:image"
          content="https://fairyraffles.com/assets/preview.png"
        />
      </Head>
      <div className="bg-sky-500 p-2 text-center text-sm text-white">
        Fairy raffles now on Arbitrum ✨ Cheaper, faster, same guaranteed
        fairness with Chainlink VRF!{" "}
        <Link
          className="underline hover:no-underline"
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/fairyrnd/status/1565660588893667330"
        >
          how to use
        </Link>
      </div>
      <Header />
      <main className="flex-1">{component}</main>
      <Footer />
    </div>
  );
}
