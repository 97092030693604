import {
  darkTheme,
  getDefaultWallets,
  lightTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { NextPage } from "next";
import { ThemeProvider } from "next-themes";
import type { AppProps } from "next/app";
import { ReactElement, ReactNode } from "react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { arbitrum as arbitrumOne } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { DefaultLayout } from "../layouts/default";
import "../styles/globals.css";
import { Analytics } from "@vercel/analytics/react";

import "@/lib/fonts";
import { bitter, opensans } from "@/lib/fonts";

const { chains, provider } = configureChains([arbitrumOne], [publicProvider()]);

const { connectors } = getDefaultWallets({
  projectId: process.env.NEXT_PUBLIC_WC_PROJECT_ID as string,
  appName: "Fairy Raffles",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? DefaultLayout;

  return (
    <ThemeProvider attribute="class">
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          coolMode
          id="rk"
          chains={chains}
          theme={{
            lightMode: lightTheme({
              accentColor: "var(--primary)",
              accentColorForeground: "var(--primary)",
            }),
            darkMode: darkTheme({
              accentColor: "var(--primary)",
              accentColorForeground: "var(--primary)",
            }),
          }}
          showRecentTransactions
        >
          <style jsx global>{`
            :root {
              --font-bitter: ${bitter.style.fontFamily};
              --font-open-sans: ${opensans.style.fontFamily};
            }
          `}</style>
          {getLayout(<Component {...pageProps} />)}
        </RainbowKitProvider>
      </WagmiConfig>
      <Analytics />
    </ThemeProvider>
  );
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};
