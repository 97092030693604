import Link from "next/link";
import { FairyIcon } from "../utils/icons";
import { ThemeSwitcher } from "./ThemeSwitcher";
import { ConnectButton } from "./ConnectButton";

export function Header() {
  return (
    <header className="flex flex-row items-center justify-between gap-4 p-12">
      <Link href="/" className="inline-flex items-center gap-2">
        <div className="w-12">{FairyIcon}</div>
        <div>
          <div className="font-bitter font-bold">Fairy Raffles</div>
          <div className="text-sm">Verifiable and fair.</div>
        </div>
      </Link>
      <div className="flex gap-4">
        <ConnectButton />

        <ThemeSwitcher />
      </div>
    </header>
  );
}
