export const FairyIcon = (
  <svg viewBox="0 0 50 50" version="1.1" preserveAspectRatio="none">
    <title>fairy_icon</title>
    <defs>
      <rect id="path-1" x="0" y="0" width="50" height="50"></rect>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="fairy_icon">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1"></use>
        </mask>
        <use
          id="Rectangle"
          fillOpacity="0"
          fill="#FFFFFF"
          xlinkHref="#path-1"
        ></use>
        <text
          id="🧚"
          fill="#000000"
          fillRule="nonzero"
          mask="url(#mask-2)"
          fontFamily="AppleColorEmoji, Apple Color Emoji"
          fontSize="48"
          fontWeight="normal"
        >
          <tspan x="1" y="43">
            🧚
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
