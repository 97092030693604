import Link from "next/link";

export function Footer() {
  return (
    <footer className="text-gray-500">
      <ul className="flex justify-center gap-8 py-8 text-xs">
        <li>© {new Date().getFullYear()} Fairy, Inc.</li>
        <li>
          <Link href="https://twitter.com/fairyrnd" className="hover:underline">
            Follow us on Twitter
          </Link>
        </li>
        <li>
          <Link href="https://docs.fairy.dev" className="hover:underline">
            Docs
          </Link>
        </li>
      </ul>
    </footer>
  );
}
